

<template>
  <div class="order-detail-box">
    <s-header :name="'订单详情'" @callback="close"></s-header>
    <div class="order-status">
      <div class="status-item">
        <label>订单状态：</label>
        <span>{{ detail.orderStatusString }}</span>
      </div>
      <div class="status-item">
        <label>订单编号：</label>
        <span>{{ detail.orderNo }}</span>
      </div>
      <div class="status-item">
        <label>下单时间：</label>
        <span>{{ detail.createTime }}</span>
      </div>
      <!-- <van-button v-if="[1,2,3].includes(detail.orderStatus)" style="margin-bottom: 10px" color="#1baeae" block @click="confirmOrder(detail.orderNo)">确认订单</van-button> -->
      <van-button v-if="detail.orderStatus == 0" style="margin-bottom: 10px" color="#1baeae" block @click="showPayFn">去支付</van-button>
      <van-button v-if="!(detail.orderStatus < 0 || detail.orderStatus == 4)" block @click="cancelOrder(detail.orderNo)">取消订单</van-button>
    </div>
    <div class="order-price">
      <div class="price-item">
        <label>商品金额：</label>
        <span>¥ {{ detail.totalPrice }}</span>
      </div>
      <div class="price-item">
        <label>配送方式：</label>
        <span>普通快递</span>
      </div>
    </div>
    <van-card
      v-for="item in detail.newBeeMallOrderItemVOS"
      :key="item.goodsId"
      style="background: #fff"
      :num="item.goodsCount"
      :price="item.sellingPrice"
      desc="全场包邮"
      :title="item.goodsName"
      :thumb="prefix(item.goodsCoverImg)"
    />
    <van-popup
      v-model="showPay"
      position="bottom"
      :style="{ height: '24%' }"
    >
      <div :style="{ width: '90%', margin: '0 auto', padding: '20px 0' }">
        <van-button :style="{ marginBottom: '10px',display:'none' }" color="#1989fa" block @click="payOrder(detail.orderNo, 1)">支付宝支付</van-button>
        <van-button color="#4fc08d" block @click="payOrder(detail.orderNo, detail.totalPrice, 2)">微信支付</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import { getOrderDetail, cancelOrder, confirmOrder, payOrder } from '../service/order'
import { Dialog, Toast } from 'vant'

export default {
  components: {
    sHeader
  },
  data() {
    return {
      detail: {},
      showPay: false
    }
  },
  mounted() {
    this.init()

  },
  methods: {
    async init() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const { id } = this.$route.query
      const { data } = await getOrderDetail(id)
      this.detail = data
      Toast.clear()
    },
    cancelOrder(id) {
      Dialog.confirm({
        title: '确认取消订单？',
      }).then(() => {
        cancelOrder(id).then(res => {
          if (res.resultCode == 200) {
            Toast('删除成功')
            this.init()
          }
        })
      }).catch(() => {
        // on cancel
      });
    },
    confirmOrder(id) {
      Dialog.confirm({
        title: '是否确认订单？',
      }).then(() => {
        confirmOrder(id).then(res => {
          if (res.resultCode == 200) {
            Toast('确认成功')
            this.init()
          }
        })
      }).catch(() => {
        // on cancel
      });
    },
    showPayFn() {
      this.showPay = true
    },
    async payOrder(id, free, type) {
      Toast.loading
      let openid = this.$store.state.openid
      console.log(openid+'222222')
      await payOrder({ fee: free, orderno: id, openid: openid, payType: type }).then(res => {
        this.handleGetDataSucc(res)
      })
      this.showPay = false
      // this.init()
    },
    handleGetDataSucc (res) {
      var vm = this
      var data = res.data
      if (res.code === 200 && data) {
      // 下面是解决WeixinJSBridge is not defined 报错的方法
        if (typeof WeixinJSBridge === 'undefined') { // 微信浏览器内置对象。参考微信官方文档
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', vm.onBridgeReady(data), false)
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', vm.onBridgeReady(data))
            document.attachEvent('onWeixinJSBridgeReady', vm.onBridgeReady(data))
          }
        } else {
          console.log('准备调用微信支付')
          vm.onBridgeReady(data)
        }
      }
    },
    onBridgeReady (data) {
      console.log('调用微信支付WeixinJSBridge')
      var vm = this
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', { // 下面参数内容都是后台返回的
          'appId': data.appId, // 公众号名称，由商户传入
          'timeStamp': data.timeStamp, // 时间戳
          'nonceStr': data.nonceStr, // 随机串
          'package': data.package, // 预支付id
          'signType': data.signType, // 微信签名方式
          'paySign': data.paySign // 微信签名
        },
        function (res) {
        // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          console.log(res)
          // if (res.err_msg === 'get_brand_wcpay_request:ok') {
          //   Dialog.alert({
          //     message: '下单成功'
          //   }).then(() => { vm.$router.push('/order') })
          // } else {
          //   Dialog.alert({
          //     message: '下单失败'
          //   }).then(() => { vm.$router.push('/order') })
          // }
        }
      )
    },
    close() {
      Dialog.close()
    }
  }
}
</script>

<style lang="less" scoped>
  .order-detail-box {
    background: #f7f7f7;
    .order-status {
      background: #fff;
      margin-top: 44px;
      padding: 20px;
      font-size: 15px;
      .status-item {
        margin-bottom: 10px;
        label {
          color: #999;
        }
        span {

        }
      }
    }
    .order-price {
      background: #fff;
      margin: 20px 0;
      padding: 20px;
      font-size: 15px;
      .price-item {
        margin-bottom: 10px;
        label {
          color: #999;
        }
        span {

        }
      }
    }
    .van-card {
      margin-top: 0;
    }
    .van-card__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
</style>
